/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 vanguard_logo.glb
*/

import React, { useEffect, useRef, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { MathUtils } from 'three';

const MARGIN = .035; // `lerp` doesn't go up to the exact value so stop a little bit prior to target
//TODO: Refactor to useRef instead of passing it back manually. Can simplify code more if ObjectSelector just held the refs of all logo model
export function VanguardLogo({ shouldRotate, getRef, animationCallback, targetScale, loadAnimation, shrink, ...props }) {
  const ref = useRef();
  const [isLoadingAnimationFinished, setIsLoadingAnimationFinished] = useState(null);
  const [shrinkAnimation, setShrinkAnimation] = useState(false);

  useFrame(({ clock }) => {
    if (shouldRotate) {
      const t = clock.getElapsedTime();
      ref.current.rotation.z = t;
    }

    if (isLoadingAnimationFinished === false) {
      if (ref.current.scale.x <= targetScale - MARGIN) {
        const lerpValue = MathUtils.lerp(ref.current.scale.x, targetScale, 0.019);
        ref.current.scale.set(lerpValue, lerpValue, lerpValue);
      } else {
        if (animationCallback) animationCallback(); // tell parent component animation is finished
        setIsLoadingAnimationFinished(true);
      }
    }

    if (shrinkAnimation === true) {
      const lerpValue = MathUtils.lerp(ref.current.scale.x, -0.02, 0.25);
      ref.current.scale.set(lerpValue, lerpValue, lerpValue);
    }

  });

  const startLoadingAnimation = () => {
    setIsLoadingAnimationFinished(false);
  };

  useEffect(() => {
    if (getRef) {
      getRef(ref);
    }

    if (loadAnimation) {
      loadAnimation(ref);
    }

    if (shrink) {
      shrink(ref);
    }
  }, []);

  const { nodes, materials } = useGLTF("models/vanguard_logo.glb");

  return (
    <group
      ref={ref}
      rotation-x={3.7}
      dispose={null}
      scale={0}
      {...props}
      startLoadingAnimation={startLoadingAnimation}
      triggerShrinkAnimation={() => { setShrinkAnimation(true); }}
    >
      <mesh geometry={nodes.Cube.geometry} material={materials.Material} />
    </group>
  );
}

useGLTF.preload('models/vanguard_logo.glb');
