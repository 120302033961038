/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.3 codearenaLogo.glb
*/

import React, { useEffect, useRef, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { MathUtils } from 'three';

const MARGIN = .035;

export function CodeArenaLogo({ shouldRotate, getRef, animationCallback, targetScale, loadAnimation, shrink, ...props }) {
  const ref = useRef();
  const [isLoadingAnimationFinished, setIsLoadingAnimationFinished] = useState(null);
  const [shrinkAnimation, setShrinkAnimation] = useState(false);

  useFrame(({ clock }) => {
    if (shouldRotate) {
      const t = clock.getElapsedTime();
      ref.current.rotation.y = t;
    }

    if (isLoadingAnimationFinished === false) {
      if (ref.current.scale.x <= targetScale - MARGIN) {
        const lerpValue = MathUtils.lerp(ref.current.scale.x, targetScale, 0.0185);
        ref.current.scale.set(lerpValue, lerpValue, lerpValue);
      } else {
        if (animationCallback) animationCallback(); // tell parent component animation is finished
        setIsLoadingAnimationFinished(true);
      }
    }

    if (shrinkAnimation === true) {
      const lerpValue = MathUtils.lerp(ref.current.scale.x, -0.02, 0.25);
      ref.current.scale.set(lerpValue, lerpValue, lerpValue);
    }

  });

  const startLoadingAnimation = () => {
    setIsLoadingAnimationFinished(false);
  };

  useEffect(() => {
    if (getRef) {
      getRef(ref);
    }

    if (loadAnimation) {
      loadAnimation(ref);
    }

    if (shrink) {
      shrink(ref);
    }
  }, []);
  const { nodes, materials } = useGLTF('models/codearenaLogo.glb');
  return (
    <group
      ref={ref}
      rotation-x={1.9}
      dispose={null}
      scale={0}
      {...props}
      startLoadingAnimation={startLoadingAnimation}
      triggerShrinkAnimation={() => { setShrinkAnimation(true); }}
    >
      <mesh geometry={nodes['1x1_R2_(1)'].geometry} material={materials.None} position={[0.02, -0.14, 0.01]} rotation={[-Math.PI / 2, 0, 0]} scale={0.15} />
      <mesh geometry={nodes.Text.geometry} material={materials['Material.001']} position={[0.02, -0.14, 0.26]} rotation={[1.47, 0.01, -0.07]} scale={0.7} />
    </group>
  );
}

useGLTF.preload('models/codearenaLogo.glb');
